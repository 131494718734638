import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Data from '../Data.json';

function AboutBtm() {
  return (
    <div className="about-btm-wraper text-center">
      {Data.aboutbottomsec.map((mav) => (
        <div className="container" key={mav.id}>
            <h2>{mav.heading}</h2>
            <div>
              <p>There are 5 DICE. When 5 DICE are rolled, there are 7776 possible combinations that they can arrive at.</p>
            </div>
              <p className="center-text">1-1-1-1-1, 1-1-1-1-2, and so on and so on up to 6-6-6-6-6.</p>
            <p>Each NFT features – an image of the 5 dice in one of the 7776 combinations, one of 52, 8-bit drawings, one of 53 quotes, and the number of the combination. The NFT traits are not important for the game, simply there to add fun and will be used in later games for NFT holders.</p>
            <p>The gameplay is straightforward: Mint the DICE to receive an NFT featuring a unique dice combination. After all NFTs are minted, a live event will stream the 5 DICE rolling twice, each roll determining a winner. Each winner from these rolls will be eligible for a $2,000 airdrop.</p>
            <div>An exclusive airdrop is also reserved for the "lucky numbers":
                <p className="center-text">1-1-1-1-1 | 2-2-2-2-2 | 3-3-3-3-3 | 4-4-4-4-4 | 5-5-5-5-5 | 6-6-6-6-6 | 1-2-3-4-5 | 5-4-3-2-1</p>
                <p>Holders of these lucky number NFTs will be eligible for a $500 airdrop. These particular NFTs will feature exclusive one-of-a-kind artwork.</p>
            </div>
            <img src={mav.image} className="img-fluid" alt="Dice Combination"/>
        </div>
      ))}
    </div>
  );
}

export default AboutBtm;
