import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Data from '../Data.json';
import parse from 'html-react-parser';



function About() {
  return (
    <div className="about-us-wraper" id="about">
        <div className="container">
            
        {Data.aboutsec.map((mav) => (
            <div  key={mav.id} className='row'>
                <div className='col-md-8 col-lg-6 pe-lg-0'>
                    <div className='lft'>
                        <h2>{mav.heading}</h2>
                        {parse(mav.para)}
                    </div>
                </div>
                <div className='col-md-4 col-lg-6 '>
                    <div className='rgt text-md-end'>
                        <img src={mav.rightimage} className="img-fluid"/>
                    </div>
                </div>
            </div>
         ))}

        </div>
    </div>
  );
}

export default About;