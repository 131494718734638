import '../App.css';
import { Accordion } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import Data from '../Data.json';
import parse from 'html-react-parser';

function About() {
    return (
        <div className="faq-wraper">
            <div className="container">
                <h2>Frequently Asked Questions</h2>
                <div  className="accordion-sec" >
                    <Accordion>
                    {Data.faq.map((mav) => (
                        <Accordion.Item key={mav.id} eventKey={mav.id}>
                            <Accordion.Header>{mav.title}</Accordion.Header>
                            <Accordion.Body>
                            {parse(mav.content)}
                            </Accordion.Body>
                        </Accordion.Item>
                    ))}

                        
                    </Accordion>
                </div>
            </div>
        </div>
    );
}

export default About;