import React from 'react';

/*
// The commented-out section is kept for reference or future use
import { useState, useEffect } from "react";
import song from "../static/Gambler.mp3";
import i1 from "../static/i1.png";
import i2 from "../static/i2.png";
import i3 from "../static/i3.png";
import i4 from "../static/i4.png";

const useAudio = url => {
  const [audio] = useState(new Audio(song));
  const [playing, setPlaying] = useState(false);

  const toggle = () => setPlaying(true);
  const pause = () => setPlaying(false);

  useEffect(() => {
    playing ? audio.play() : audio.pause();
  }, [playing]);

  useEffect(() => {
    audio.addEventListener('ended', () => setPlaying(false));
    return () => {
      audio.removeEventListener('ended', () => setPlaying(false));
    };
  }, []);

  return [playing, { toggle, pause }];
};

const Layout = ({ url }) => {
  const [playing, { toggle, pause }] = useAudio(url);

  return (
    <ul className="hd-rgt d-flex align-items-center ps-0 ms-0">
      <li><button onClick={toggle}><img src={i1} alt="Icon 1" /></button></li>
      <li><button id="playa" onClick={pause}><img src={i2} alt="Icon 2" /></button></li>
      <li><a href="#"><img src={i3} alt="Icon 3" /></a></li>
      <li><a href="#"><img src={i4} alt="Icon 4" /></a></li>
    </ul>
  );
};
*/

// Active Layout component
const Layout = () => {
  return (
    <ul className="hd-rgt d-flex align-items-center ps-0 ms-0">
      {/* Add list items or other content here */}
    </ul>
  );
};

export default Layout;
