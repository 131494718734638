import React, { useState, useEffect, useRef } from 'react';
import Web3 from 'web3';
import FiveDice from './abis/FiveDice.json';
import Banner from './components/Banner';
import About from './components/About';
import AboutBtm from './components/AboutBtm';
import Faq from './components/Faq';

import './App.css';

import NotificationBar from './components/NotificationBar';



function App() {
  const [account, setAccount] = useState('');
  const [fiveDiceContract, setFiveDiceContract] = useState(null);

  // This effect will run once on component mount, to initialize Web3 and blockchain data
  useEffect(() => {
    const loadWeb3 = async () => {
      if (window.ethereum) {
        window.web3 = new Web3(window.ethereum);
        try {
          // Request account access if needed
          await window.ethereum.request({ method: 'eth_requestAccounts' });
        } catch (error) {
          console.error("User denied account access:", error);
        }
      } else if (window.web3) {
        window.web3 = new Web3(window.web3.currentProvider);
      } else {
        window.alert('Non-Ethereum browser detected. You should consider trying MetaMask!');
      }
    };

    const loadBlockchainData = async () => {
      const web3 = window.web3;
      const accounts = await web3.eth.getAccounts();
      setAccount(accounts[0]);
      const networkId = await web3.eth.net.getId();
      const networkData = FiveDice.networks[networkId];
      if (networkData) {
        const contract = new web3.eth.Contract(FiveDice.abi, networkData.address);
        setFiveDiceContract(contract);
      } else {
        window.alert('FiveDice contract not deployed to detected network.');
      }
    };

    loadWeb3().then(loadBlockchainData);

    // Listening for account changes
    window.ethereum.on('accountsChanged', function (accounts) {
      setAccount(accounts[0]);
    });

    // Listening for network changes
    window.ethereum.on('chainChanged', (chainId) => {
      window.location.reload();
    });

  }, []);

  // Function to interact with contract
  const mintNFTHandler = async () => {
    if (fiveDiceContract) {
      try {
        // Function to mint an NFT
        const result = await fiveDiceContract.methods.mint().send({ from: account });
        console.log(result);
      } catch (error) {
        console.error("An error occurred while minting NFT: ", error);
      }
    }
  };

  return (
    <div className="main-wraper">
      <NotificationBar />
     
      <Banner />
      
      <About />
      <AboutBtm />
      <Faq />
      
    </div>
  );
}

export default App;
