import React, { useState, useEffect } from 'react';
import Web3 from 'web3';
import '../App.css';
import Data from '../Data.json';

function Banner() {
  const [account, setAccount] = useState(null);

  const web3Handler = async () => {
    if (window.ethereum) {
      try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        setAccount(accounts[0]);
      } catch (error) {
        console.error("Error on connecting: ", error);
      }
    } else {
      alert('Please install MetaMask to use this feature!');
    }
  };

  useEffect(() => {
    if (window.ethereum) {
      const handleAccountsChanged = (accounts) => {
        if (accounts.length === 0) {
          console.log('Please connect to MetaMask.');
        } else if (accounts[0] !== account) {
          setAccount(accounts[0]);
        }
      };

      window.ethereum.on('accountsChanged', handleAccountsChanged);

      return () => {
        window.ethereum.removeListener('accountsChanged', handleAccountsChanged);
      };
    }
  }, [account]); // Dependency array ensures effect is re-run if account changes

  return (
    <div className="banner">
      {Data.banner.map((mapv) => (
        <div key={mapv.id}>
          <img src={mapv.image} alt="Banner"/>
          <button className="orange-btn" onClick={web3Handler}>
            {account ? `${account.substring(0, 4)}...${account.substring(account.length - 4)}` : mapv.buttontext}
          </button>
        </div>
      ))}
    </div>
  );
}

export default Banner;
