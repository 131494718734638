import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Outlet, Link } from "react-router-dom";
import logo from "../static/logo.png";
import Layout from './Layout';
import i3 from "../static/i3.png";
import i4 from "../static/i4.png";





function Header() {
    return (
        <header>
            <div className="header-wraper" >
                <div className="container">
                    <div className='row align-items-center'>
                        <div className='col-md-2 col-lg-3 pe-lg-0 logo'>

                            
                            <Link to="/"><img src={logo} alt="store" className="img-fluid"/></Link>

                        </div>
                        <div className='col-md-10 col-lg-9 '>
                            <div className='rgt text-md-end '>
                                <nav className="d-flex justify-content-md-end justify-content-center align-items-center">
                                    <ul className="d-flex align-items-center">
                                       
                                        <li>
                                            <button className="orange-btn">Mint the dice</button>
                                        </li>
                                        <li >
                                            <a href="#about" className="orange-btn">About</a>
                                        </li>
                                        <li>
                                            <a href="https://www.okx.com/pl/web3/marketplace/nft/collection/zksync-era/zksync-era-name-service" target="_blank" rel="noopener noreferrer" className="orange-btn">Collection</a>
                                        </li>
                                        <li>
                                            <a href="#"> <img src={i3} /></a>
                                        </li>
                                        <li>
                                            <a href="#"> <img src={i4} /></a>
                                        </li>
                                    </ul>
                                    <Layout />
                                </nav>

                                
                                

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Outlet />
        </header>

    );
}

export default Header;