// src/components/NotificationBar.js

import React from 'react';
import './NotificationBar.css';

function NotificationBar() {
  return (
    <div className="notification-bar">
      Minting is currently in private testing phase. ABIs are off.
    </div>
  );
}

export default NotificationBar;
