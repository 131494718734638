import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Data from '../Data.json';

function Footer() {
  return (
    <footer>
      <div className="footer-wraper py-4 text-center">
        {Data.footer.map((mav) => (
          <div key={mav.id} className="container">
            <img src={mav.image} alt="Logo" />
            <p>
              {mav.text.split('myweb3apps')[0]}
              <a href="https://myweb3apps.com" target="_blank" rel="noopener noreferrer">myweb3apps</a>
              {mav.text.split('myweb3apps')[1]}
            </p>
          </div>
        ))}
      </div>
    </footer>
  );
}

export default Footer;
